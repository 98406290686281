import { Box, SystemProps } from "@storyofams/react-ui";

import { usePostHog } from "posthog-js/react";

type CodeEmbedProps = {
  content: {
    code: string;
  };
} & SystemProps;

export const CodeEmbed = ({ content, ...props }: CodeEmbedProps) => {
  return (
    <Box
      // onClick={() => posthog.capture("Clicked Greenspark Homepage")}
      {...props}
      dangerouslySetInnerHTML={{ __html: content?.code }}
    />
  );
};
